.Research{
    font-family : 'ONE-Mobile-Title';
}

/* pc */
/* ci */
.Research_PC-banner{
    padding: 0 60px;
    height: 400px;
    box-sizing: border-box;
    border-bottom-width: 2px;
    border-bottom-color: var(--mainFontColor1);
    color : var(--mainBackgroundcolor);
    background-image: url('https://firebasestorage.googleapis.com/v0/b/shinafastec.appspot.com/o/Research%2FCK_pc002286554%20(1).jpg?alt=media&token=3bc3fec4-2519-45ed-b292-4d585785a9d8');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
    background-color: rgba(0,0,0,0.5);
    background-blend-mode: multiply;
}

.Research_PC-banner-top{
    font-size: 48px;
    font-weight: bold;
}

.Research_PC-banner-menu > ul {
    font-size: 18px;
    width: 300px;
}

.Research_PC-Intro, .Research_PC-Products{
    font-size: 36px;
    font-weight: bold;
}
/* ci */
.Research_PC-Intro .Contents{
    font-size: 20px;
    font-weight: normal;
}

/* history */
.Research_PC-Products .Contents-Title{
    font-size: 28px;
    position: relative;
    padding-left: 20px;
}

.Research_PC-Products .Contents-Title::after{
    position: absolute;
    content: "";
    top: -50%;
    left: 0;
    width: 22px;
    height: 22px;
    background-color: var(--mainWidgetcolor);
    border-radius: 100%;
}

.Research_PC-Products .Contents-SubTitle{
    font-size: 24px;
    font-weight: 900;
}

.Research_PC-Products .Contents-List{
    font-size: 24px;
    font-weight: normal;
    padding-left: 40px;
}

.Research_PC-Products .Contents-List img{
    height: 300px;
    object-fit: contain;
    padding: 10px;
}


.Research_PC-Products .Contents-List .Sinksense img{
    height: 400px;
    object-fit: contain;
    padding: 10px;
}

.Research_PC-Products .Contents-List p{
    font-size: 18px;
}

.Research_PC-Products .Contents-List button{
    font-size: 14px;
    padding: 10px 20px;
    border-radius: 20px;
    border: 2px solid;
    border-color: var(--mainFontColor1);
}

/* mobile */
/* ci */
.Research_MB-banner{
    padding: 0 30px;
    height: 300px;
    box-sizing: border-box;
    border-bottom-width: 2px;
    border-bottom-color: var(--mainFontColor1);
    color : var(--mainBackgroundcolor);
    background-image: url('https://firebasestorage.googleapis.com/v0/b/shinafastec.appspot.com/o/Research%2FCK_pc002286554%20(1).jpg?alt=media&token=3bc3fec4-2519-45ed-b292-4d585785a9d8');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
    background-color: rgba(0,0,0,0.5);
    background-blend-mode: multiply;
}

.Research_MB-banner-top{
    font-size: 36px;
    font-weight: bold;
}

.Research_MB-banner-menu > ul {
    font-size: 14px;
    width: 200px;
}

.Research_MB-Intro, .Research_MB-Products{
    font-size: 24px;
    font-weight: bold;
    padding: 0 30px;
}
/* ci */
.Research_MB-Intro .Contents{
    font-size: 16px;
    font-weight: normal;
}

/* history */
.Research_MB-Products .Contents-Title{
    font-size: 20px;
    position: relative;
    padding-left: 10px;
}

.Research_MB-Products .Contents-Title::after{
    position: absolute;
    content: "";
    top: -20px;
    left: 0;
    width: 14px;
    height: 14px;
    background-color: var(--mainWidgetcolor);
    border-radius: 100%;
}

.Research_MB-Products .Contents-SubTitle{
    font-size: 18px;
    font-weight: 900;
}

.Research_MB-Products .Contents-List{
    font-size: 16px;
    font-weight: normal;
    padding-left: 14px;
}

.Research_MB-Products .Contents-List img{
    height: 240px;
    object-fit: contain;
    padding: 10px;
}

.Research_MB-Products .Contents-List .Sinksense img{
    height: 280px;
    object-fit: contain;
    padding: 10px;
}

.Research_MB-Products .Contents-List p{
    font-size: 14px;
}

.Research_MB-Products .Contents-List button{
    font-size: 14px;
    padding: 10px 20px;
    border-radius: 20px;
    border: 2px solid;
    border-color: var(--mainFontColor1);
}

@media only screen and (max-width: 380px) {
    .Research_MB{
        zoom: 0.6;
    }
}

@media only screen and (max-width: 900px) {
    .Research_PC{
        display: none ;
    }
    .Research_MB {
        display: flex;
    }
}

@media only screen and (min-width: 901px) and (max-width : 1199px) {
    .Research_PC{
        display: flex ;
        zoom: 0.7;
    }
    .Research_MB {
        display: none;
    }
}
  
@media only screen and (min-width: 1200px) {
    .Research_PC{
        display: flex ;
    }
    .Research_MB {
        display: none;
    }
}

@media only screen and (min-width: 1921px) and (max-width: 2560px ) {
    .Research_PC{
        zoom: 1.2;
    }
}

@media only screen and (min-width: 2561px){
    .Research_PC{
        zoom: 1.4;
    }
}