.Archaive{
    font-family : 'ONE-Mobile-Title';
}

/* pc */
.Archaive_PC{
    height: calc(100vh - 80px);
}

.Archaive_PC-Archaive{
    font-size: 32px;
    font-weight: normal;
}

.Archaive_PC-Archaive > div{
    width: 90%;
}

/* history */
.Archaive_PC-Archaive .MenuArea{
    height: 40vh;
    overflow-y : auto;
    font-size: 84px;
}

.Archaive_PC-Archaive .ArchaiveList .List{
    width: 100%;
    /* overflow: hidden; */
    border-bottom: 1px solid;
    border-bottom-color: var(--mainFontColor1);
    border-top: 2px solid;
    border-top-color: var(--mainFontColor1);
}

.Archaive_PC-Archaive .ArchaiveList .Lists > div{
    font-size: 12px;
    font-weight: lighter;
    padding: 0 20px;
    border-bottom: 1px solid;
    border-bottom-color: var(--mainFontColor1);
}

.Archaive_PC-Archaive .ArchaiveList .Lists .HeadLines > div{
    padding: 0 30px 20px 30px;
}

.Archaive_PC-Archaive .ArchaiveList .Lists .HeadLines > div:nth-child(3){
    padding : 30px 0 30px 0;
    font-size: 30px;
}

.Archaive_PC-Archaive .ArchaiveList .Lists .HeadLines > div:nth-child(4){
    padding : 30px 0 30px 15px;
    font-size: 20px;
}

.Archaive_PC-Archaive .ArchaiveList .Lists .Contents{
    height: 0;
    padding: 0 40px;
    overflow: hidden;
    overflow-y: auto;
    transition: height 1s, padding 1s;
}

.Archaive_PC-Archaive .ArchaiveList .Lists .Contents.active{
    height: 300px;
    padding: 30px 40px;
}

.Archaive_PC-Archaive .ArchaiveList .PagingTool{
    margin-top: 20px;
}

.Archaive_PC-Archaive .ArchaiveList .PagingTool .swiper-button-disabled::before{
    color: var(--mainFontColor2);
}

.Archaive_PC-Archaive .ArchaiveList .PagingTool .swiper-pagination-bullet{
    font-size: 14px;
    width: 28px;
    height: 28px;
    border-radius: 4px;
    padding: 4px;
    text-align: center;
}

.Archaive_PC-Archaive .ArchaiveList .PagingTool .swiper-pagination-bullet-active{
    background-color: var(--mainFontColor1);
    color: var(--subBackgroundcolor);
}

.Archaive_PC-Archaive .ArchaiveList .fa-exclamation {
    background-color: var(--mainFontColor1);
    color: var(--mainBackgroundcolor);
    padding: 16px 28px;
    border-radius: 100%;
    margin-bottom: 20px;
}
/* mobile */
.Archaive_MB{
    font-size: 28px;
    font-weight: normal;
}

.Archaive_MB > .ArchaiveBanner {
    padding: 40px 30px 0 30px;
    font-size: 36px;
    font-weight: bold;
}

.Archaive_MB > .ArchaiveList {
    padding: 0 30px;
}

.Archaive_MB .ArchaiveList .List{
    width: 100%;
    /* overflow: hidden; */
    border-bottom: 1px solid;
    border-bottom-color: var(--mainFontColor1);
    border-top: 2px solid;
    border-top-color: var(--mainFontColor1);
}

.Archaive_MB .ArchaiveList .Lists > div{
    font-size: 12px;
    font-weight: lighter;
    padding: 10px 20px;
    border-bottom: 1px solid;
    border-bottom-color: var(--mainFontColor1);
}

.Archaive_MB .ArchaiveList .Lists .HeadLines > div:nth-child(1){
    padding : 10px 0 10px 0;
    font-size: 22px;
}

.Archaive_MB .ArchaiveList .Lists .HeadLines > div:nth-child(2){
    padding : 10px 0 10px 15px;
    font-size: 20px;
}

.Archaive_MB .ArchaiveList .Lists .Contents{
    height: 0;
    padding: 0 10px;
    overflow: hidden;
    overflow-y: auto;
    transition: height 1s, padding 1s;
}

.Archaive_MB .ArchaiveList .Lists .Contents.active{
    height: 200px;
    padding: 30px 10px;
}

.Archaive_MB .ArchaiveList .PagingTool{
    margin-top: 20px;
}

.Archaive_MB .ArchaiveList .PagingTool .swiper-button-disabled::before{
    color: var(--mainFontColor2);
}

.Archaive_MB .ArchaiveList .fa-exclamation {
    background-color: var(--mainFontColor1);
    color: var(--mainBackgroundcolor);
    padding: 16px 24px;
    border-radius: 100%;
    margin-bottom: 20px;
}

@media only screen and (max-width: 380px) {
    .Archaive_MB{
        zoom: 0.6;
    }
}

@media only screen and (max-width: 900px) {
    .Archaive_PC{
        display: none ;
    }
    .Archaive_MB {
        display: flex;
    }
}

@media only screen and (min-width: 901px) and (max-width : 1199px) {
    .Archaive_PC{
        display: flex ;
        zoom: 0.7;
    }
    .Archaive_MB {
        display: none;
    }
}
  
@media only screen and (min-width: 1200px) {
    .Archaive_PC{
        display: flex ;
    }
    .Archaive_MB {
        display: none;
    }
}

@media only screen and (min-width: 1921px) and (max-width: 2560px ) {
    .Archaive_PC{
        zoom: 1.2;
    }
}

@media only screen and (min-width: 2561px){
    .Archaive_PC{
        zoom: 1.4;
    }
}