.Location{
    font-family : 'ONE-Mobile-Title';
}

/* pc */
/* ci */
.Location_PC-banner{
    padding: 0 60px;
    height: 400px;
    box-sizing: border-box;
    border-bottom-width: 2px;
    border-bottom-color: var(--mainFontColor1);
    color : var(--mainBackgroundcolor);
    background-image: url('https://firebasestorage.googleapis.com/v0/b/shinafastec.appspot.com/o/AboutUs%2Farchitecture-1448221_1920.jpg?alt=media&token=08a372ac-f5fb-4f24-bff4-1f33de50a966');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 100% 40%;
    background-color: rgba(0,0,0,0.3);
    background-blend-mode: multiply;

}

.Location_PC-banner-top{
    font-size: 48px;
    font-weight: bold;
}
/* ci */
.Location_PC-CI{
    font-size: 36px;
    font-weight: bold;
    padding : 20px;
}

.Location_PC-CI-lists span{
    font-size: 20px;
    font-weight: bold;
}

.Location_PC-CI-lists p{
    font-size: 20px;
    font-weight: normal;
}

.Location_PC-CI-lists img{
    border-radius: 4px;
    margin-bottom: 20px;
    width: 900px;
    object-fit: contain;
    filter: grayscale(0);
}

/* mobile */
.Location_MB-banner{
    padding: 0 30px;
    height: 300px;
    box-sizing: border-box;
    border-bottom-width: 2px;
    border-bottom-color: var(--mainFontColor1);
    color : var(--mainBackgroundcolor);
    background-image: url('https://firebasestorage.googleapis.com/v0/b/shinafastec.appspot.com/o/AboutUs%2Farchitecture-1448221_1920.jpg?alt=media&token=08a372ac-f5fb-4f24-bff4-1f33de50a966');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 100% 40%;
    background-color: rgba(0,0,0,0.3);
    background-blend-mode: multiply;
}

.Location_MB-banner-top{
    font-size: 36px;
    font-weight: bold;
}
/* ci */
.Location_MB-CI{
    font-size: 32px;
    font-weight: bold;
    padding: 0 30px;
}

.Location_MB-CI-lists{
    font-size: 24px;
    padding: 20px 0 0 0;
}

.Location_MB-CI-lists span{
    font-size: 14px;
    font-weight: bold;
}

.Location_MB-CI-lists p{
    font-size: 14px;
    font-weight: normal;
}

.Location_MB-CI-lists img{
    border-radius: 4px;
    margin-bottom: 20px;
    filter: grayscale(0);
    object-fit: contain;
}

@media only screen and (max-width: 380px) {
    .Location_MB{
        zoom: 0.6;
    }
}

@media only screen and (max-width: 900px) {
    .Location_PC{
        display: none ;
    }
    .Location_MB {
        display: flex;
    }
}

@media only screen and (min-width: 901px) and (max-width : 1199px) {
    .Location_PC{
        display: flex ;
        zoom: 0.7;
    }
    .Location_MB {
        display: none;
    }
}
  
@media only screen and (min-width: 1200px) {
    .Location_PC{
        display: flex ;
    }
    .Location_MB {
        display: none;
    }
}

@media only screen and (min-width: 1921px) and (max-width: 2560px ) {
    .Location_PC{
        zoom: 1.2;
    }
}

@media only screen and (min-width: 2561px){
    .Location_PC{
        zoom: 1.4;
    }
}