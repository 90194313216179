.Contact{
    font-family : 'ONE-Mobile-Title';
}

/* pc */
.Contact_PC{
    width: 80%;
    margin: auto;
    padding-top: 150px;
    min-height: calc(100vh - 80px);
}

.Contact_PC-top{
    font-size: 72px;
}

.Contact_PC-left-info, .Contact_PC-right{
    font-size: 36px;
    font-weight: bold;
}

/* mb */
.Contact_MB{
    padding: 0 30px;
}

.Contact_MB-top > div{
    font-size: 28px;
    font-weight: bold;
    padding-left: 16px;
}

.Contact_MB-top > div:first-child{
    font-size: 64px;
    padding-left: 0px;
}

.Contact_MB-top > p{
    font-weight: normal;
    font-size: 24px;
    padding: 4px 0 0 20px;  
}

@media only screen and (max-width: 380px) {
    .Contact_MB{
        zoom: 0.6;
    }
}

@media only screen and (max-width: 900px) {
    .Contact_PC{
        display: none ;
    }
    .Contact_MB {
        display: flex;
    }
}

@media only screen and (min-width: 901px) and (max-width : 1199px) {
    .Contact_PC{
        display: flex ;
        zoom: 0.7;
    }
    .Contact_MB {
        display: none;
    }
}
  
@media only screen and (min-width: 1200px) {
    .Contact_PC{
        display: flex ;
    }
    .Contact_MB {
        display: none;
    }
}

@media only screen and (min-width: 1921px) and (max-width: 2560px ) {
    .Contact_PC{
        zoom: 1.2;
    }
}

@media only screen and (min-width: 2561px){
    .Contact_PC{
        zoom: 1.4;
    }
}