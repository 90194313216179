.Process{
    font-family : 'ONE-Mobile-Title';
}

/* pc */
.Process_PC-Process{
    font-size: 32px;
    font-weight: normal;
}

/* history */
.Process_PC-Process .MenuArea{
    position: sticky;
    top : 0;
    height: calc(100vh - 80px);
    min-width: 200px;
    padding : 50px 0 0 10px;
    overflow-y : auto;
}

.Process_PC-Process .MenuAreaList > ul > li{
    font-size: 24px;
    margin-left : 4px;
    margin-bottom: 16px;
}

.Process_PC-Process .MenuAreaList > ul > li > a{
    position: relative;
}

.Process_PC-Process .MenuAreaList > ul > li > a::after{
    content: "";
    position: absolute;
    top: 120%;
    left: 0;
    width: 0;
    height: 3px;
    background-color: var(--mainFontColor1);
    transition: width 0.6s;
}

.Process_PC-Process .MenuAreaList > ul > li:hover > a::after{
    content: "";
    width: 100%;
}

.Process_PC-Process .ProcessList{
    margin: 0 0 0 40px;
}

.Process_PC-Process .ProcessList > div > p{
    margin-top: 2px;
    margin-left: 8px;
    font-size: 18px;
    font-weight: lighter;
}

.Process_PC-Process .Middles{
    font-size: 28px;
}

.Process_PC-Process .Lists{
    font-size: 16px;
}

/* .Process_PC-Process .Lists:last-child{
    flex-grow: 0;
} */

/* .Process_PC-Process .Lists:last-child > div:last-child{
    display: none;
} */

.Process_PC-Process .ListsIMG {
    width: 90px;
    height: 90px;
    overflow: hidden;
    border: 1px solid;
    border-color: var(--mainFontColor1);
    border-radius: 100%;
}

.Process_PC-Process .Lists img {
    object-fit: cover;
    width: 100%; height: 100%;
}

.Process_PC-Process .Contexts{
    font-size: 16px;
    border-top: 1px solid;
    border-top-color: var(--mainFontColor1);
    border-bottom: 1px solid;
    border-bottom-color: var(--mainFontColor1);
    padding: 15px 20px;
}

.Process_PC-Process .Contexts:first-child{
    border-top-width : 2px;
}

.Process_PC-Process .Contexts:last-child{
    border-bottom-width : 2px;
}

.Process_PC-Process .ContextsIMG {
    width: 220px;
    height: 140px;
    overflow: hidden;
}

.Process_PC-Process .Contexts img {
    object-fit: cover;
    width: 100%; height: 100%;
    border-radius: 4px;
}

.Process_PC-Process .Contexts span {
    font-size: 20px;
    margin-bottom: 5px;
}

/* mobile */
.Process_MB{
    font-size: 28px;
    font-weight: normal;
}

.Process_MB > .ProcessBanner {
    padding: 40px 30px 0 30px;
    font-size: 36px;
    font-weight: bold;
}

.Process_MB > .ProcessBanner > p {
    font-size: 18px;
    font-weight: normal;
    margin-top: 10px;
}

.Process_MB > .ProcessList {
    padding: 0 30px;
}

.Process_MB > .ProcessList .Lists{
    font-size: 12px;
    width: 150px;
    margin-bottom: 14px;
}

.Process_MB > .ProcessList .Lists:last-child {
    flex-grow: 0;
    flex-shrink: 1;
}

.Process_MB > .ProcessList .Lists:last-child > div > div:last-child{
    display: none;
}

.Process_MB > .ProcessList .ListsIMG {
    width: 80px;
    height: 80px;
    overflow: hidden;
    border: 1px solid;
    border-color: var(--mainFontColor1);
    border-radius: 100%;
}

.Process_MB > .ProcessList .Lists img {
    object-fit: cover;
    width: 100%; height: 100%;
}

.Process_MB > .ProcessList .swiper-wrapper{
    position: relative;
}

.Process_MB > .ProcessList .swiper-pagination-progressbar {
    position: absolute;
    top: auto; bottom: 0;
}

.Process_MB > .ProcessList .swiper-pagination-progressbar-fill{
    background-color: var(--mainFontColor2);
}

.Process_MB > .ProcessList .AccordianList > ul {
    border-color: var(--mainFontColor1);
    border-bottom-width: 1px;
}

.Process_MB > .ProcessList .AccordianList .list-top{
    padding: 30px 0 10px 0;
    font-size: 22px;
    border-color: var(--mainFontColor1);
    border-top-width: 1px;   
}


.Process_MB > .ProcessList .AccordianList .Contexts:first-child .list-top{
    border-top-width: 0;   
}

.Process_MB > .ProcessList .AccordianList .list-bottom{
    font-size: 14px;
    padding: 0 0 30px 0;
}

.Process_MB > .ProcessList .AccordianList .list-bottom img{
    object-fit: cover;   
    border-radius: 10px;
}

@media only screen and (max-width: 380px) {
    .Process_MB{
        zoom: 0.6;
    }
}

@media only screen and (max-width: 900px) {
    .Process_PC{
        display: none ;
    }
    .Process_MB {
        display: flex;
    }
}

@media only screen and (min-width: 901px) and (max-width : 1199px) {
    .Process_PC{
        display: flex ;
        zoom: 0.7;
    }
    .Process_MB {
        display: none;
    }
}
  
@media only screen and (min-width: 1200px) {
    .Process_PC{
        display: flex ;
    }
    .Process_MB {
        display: none;
    }
}

@media only screen and (min-width: 1921px) and (max-width: 2560px ) {
    .Process_PC{
        zoom: 1.2;
    }
}

@media only screen and (min-width: 2561px){
    .Process_PC{
        zoom: 1.4;
    }
}