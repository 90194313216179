.AboutUs{
    font-family : 'ONE-Mobile-Title';
    position: relative;
}

/* pc */
/* ci */
.AboutUs_PC-banner{
    padding: 0 60px;
    height: 400px;
    box-sizing: border-box;
    border-bottom-width: 2px;
    border-bottom-color: var(--mainFontColor1);
    color : var(--mainBackgroundcolor);
    background-image: url('https://firebasestorage.googleapis.com/v0/b/shinafastec.appspot.com/o/AboutUs%2Fmodern-4428919_1920.jpg?alt=media&token=9b629d84-8e27-4fc5-a85d-d0c849ca72ef');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 10%;
    background-color: rgba(0,0,0,0.2);
    background-blend-mode: multiply;
}

.AboutUs_PC-banner-top{
    font-size: 48px;
    font-weight: bold;
}

.AboutUs_PC-sub-menu{
    position: sticky;
    top: 80px;
    left: 0;
    right: 0;
    background-color: var(--subBackgroundcolor);
    color : var(--mainFontColor2);
}

.AboutUs_PC-sub-menu > ul {
    font-size: 16px;
    height: 50px;
    width: 1200px;
    /* padding: 0 30px 0 0; */
}

.AboutUs_PC-sub-menu > ul li {
    width: 100px;
}

.AboutUs_PC-banner-menu > ul {
    font-size: 18px;
    width: 400px;
}

.AboutUs_PC-CI, .AboutUs_PC-History, .AboutUs_PC-Ogz, .AboutUs_PC-Customer{
    font-size: 36px;
    font-weight: bold;
}
/* ci */
.AboutUs_PC-CI-lists{
    font-size: 20px;
    font-weight: normal;
    width: 280px;
    padding: 20px;
    border-radius: 10px;
    background-color: var(--subBackgroundcolor);
}

.AboutUs_PC-CI-lists > img{
    border-radius: 4px;
    margin-bottom: 20px;
    background-color: var(--mainBackgroundcolor);
}

.AboutUs_PC-CI-lists >div:last-child{
    font-size: 16px;
}


/* history */
.AboutUs_PC-History .Years{
    padding : 30px 50px 0 0;
}
.AboutUs_PC-History .Years > div{
    position: sticky;
    top : 180px;
}

.AboutUs_PC-History .Months{
    padding : 20px 0 20px 40px;
    font-size : 22px;
    font-weight : normal;
    color: var(--mainFontColor2);
    border-left-width : 2px;
    border-left-color : var(--mainFontColor2);
}

.AboutUs_PC-History .Months.Sticky{
    color: var(--mainFontcolor);
    border-left-width : 4px;
}

.AboutUs_PC-History .Months > div:first-child{
    font-size : 28px;
    font-weight : bold;
    width : 160px;
}
/* ogz */
.AboutUs_PC-Ogz .Pictures1 img {
   height: 500px;
}

.AboutUs_PC-Ogz .Pictures2 img {
    height: 500px;
}
/* customer */
.AboutUs_PC-Customer-lists{
    font-size: 14px;
    font-weight: lighter;
    border: 1px solid ;
    border-color: var(--mainFontcolor2);
    border-radius: 10px;
    padding: 10px;
    width: 280px;
    margin-right: 20px;
}

.AboutUs_PC-Customer-lists > div > img{
    height: 80px;
    margin: 10px 0;
    object-fit: contain;
    background-color: var(--mainBackgroundcolor);
}
/* mobile */
/* ci */
.AboutUs_MB-banner{
    padding: 0 30px;
    height: 300px;
    box-sizing: border-box;
    border-bottom-width: 2px;
    border-bottom-color: var(--mainFontColor1);
    color : var(--mainBackgroundcolor);
    background-image: url('https://firebasestorage.googleapis.com/v0/b/shinafastec.appspot.com/o/AboutUs%2Fmodern-4428919_1920.jpg?alt=media&token=9b629d84-8e27-4fc5-a85d-d0c849ca72ef');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 0% 20%;
    background-color: rgba(0,0,0,0.2);
    background-blend-mode: multiply;
}

.AboutUs_MB-sub-menu{
    position: sticky;
    top: 80px;
    left: 0;
    right: 0;
    z-index: 998;
}

.AboutUs_MB-sub-menu > ul {
    font-size: 14px;
    height: 40px;
    padding: 0 30px;
    background-color: var(--subBackgroundcolor);
    color : var(--mainFontColor2);
}

.AboutUs_MB-banner-top{
    font-size: 36px;
    font-weight: bold;
}

.AboutUs_MB-banner-menu > ul {
    font-size: 14px;
}

.AboutUs_MB-CI, .AboutUs_MB-History, .AboutUs_MB-Ogz, .AboutUs_MB-Customer{
    font-size: 32px;
    font-weight: bold;
    padding: 0 30px;
}
/* ci */
.AboutUs_MB-CI-lists{
    font-size: 20px;
    font-weight: normal;
    padding: 20px 0;
    border-bottom: 1px solid;
    border-color: var(--mainFontColor1);
}

.AboutUs_MB-CI-lists .ListsIMG{
    position: relative;
    width: 30%;
}

.AboutUs_MB-CI-lists .ListsIMG:before {
    content: "";
    display: block;
    padding-top: 100%; /* 1:1 비율 */
}

.AboutUs_MB-CI-lists .ListsIMG img{
    border-radius: 4px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    object-fit: contain;
}

.AboutUs_MB-CI-lists p{
    font-size: 12px;
}

/* history */
.AboutUs_MB-History .Years > div{
    position: sticky;
    top : 150px;
    font-size: 20px;
}

.AboutUs_MB-History .Months{
    font-size : 16px;
    font-weight : normal;
    padding: 10px 0 10px 14px;
    color: var(--mainFontColor2);
    border-left-width : 2px;
    border-left-color : var(--mainFontColor2);
}

.AboutUs_MB-History .Months.Sticky{
    color: var(--mainFontcolor);
    border-left-width : 4px;
}

.AboutUs_MB-History .Months > div:first-child{
    font-size : 20px;
    font-weight : bold;
}
/* ogz */
.AboutUs_MB-Ogz img {
   object-fit: contain;
}
/* customer */
.AboutUs_MB-Customer-lists{
    font-size: 12px;
    font-weight: lighter;
    border: 1px solid ;
    border-color: var(--mainFontcolor2);
    border-radius: 10px;
    padding: 10px;
}

.AboutUs_MB-Customer-lists > div > img{
    width: 80%;
    height: 70px;
    object-fit: contain;
    background-color: var(--mainBackgroundcolor);
}

@media only screen and (max-width: 380px) {
    .AboutUs_MB{
        zoom: 0.6;
    }
}

@media only screen and (max-width: 900px) {
    .AboutUs_PC{
        display: none ;
    }
    .AboutUs_MB {
        display: flex;
    }
}

@media only screen and (min-width: 901px) and (max-width : 1199px) {
    .AboutUs_PC{
        display: flex ;
        zoom: 0.7;
    }
    .AboutUs_MB {
        display: none;
    }
}
  
@media only screen and (min-width: 1200px) {
    .AboutUs_PC{
        display: flex ;
    }
    .AboutUs_MB {
        display: none;
    }
}

@media only screen and (min-width: 1921px) and (max-width: 2560px ) {
    .AboutUs_PC{
        zoom: 1.2;
    }
}

@media only screen and (min-width: 2561px){
    .AboutUs_PC{
        zoom: 1.4;
    }
}