.Home{
    font-family : 'ONE-Mobile-Title';
}

.rotated-icon {
    transform: rotate(180deg);
}

.BigTitle{
    font-size: 36px;
    position: relative;
    padding-left: 25px;
    color: var(--mainBackgroundcolor);
}

.BigTitle::after{
    position: absolute;
    content: "";
    top: -30%;
    left: 0;
    width: 22px;
    height: 22px;
    background-color: var(--mainWidgetcolor);
    border-radius: 100%;
}

/* pc */
.Home_PC-top {
    height: calc(100vh - 80px);
    padding-top: 50px;
}

.Home_PC-top p{
    text-align: center;
    font-size: 22px;
    padding: 2px 0;
}

.Home_PC-top .Text{
    text-align: center;
    font-size: 36px;
    font-weight: 900;
    margin-bottom: 10px;
}

.Home_PC-top img{
    width: 15%;
    margin-top: -60px;
    margin-bottom: 40px;
}

.Home_PC-Products, .Home_PC-Patent, .Home_PC-Trade, .Home_PC-News, .Home_PC-Contact{
    padding: 100px 0;
    background-color: var(--mainFontColor1);
    color: var(--mainBackgroundcolor);
}

/* product */
.Home_PC-Products .Contents{
    margin-top: 40px;
}

.Home_PC-Products .Contents-List {
    height: 560px;
}

.Home_PC-Products .Contents-List .MenuSide{
    width: 480px;
    background-color: var(--subBackgroundcolor);
    border-radius: 30px 0 0 30px;
}

.Home_PC-Products .Contents-List .MenuSide img{
    width: 450px;
    height: 450px;
    object-fit: contain;
    margin: 0 0px 0 0;
}

.Home_PC-Products .Contents-List .ContextSide{
    background-color: var(--mainFontColor1);
    border-radius: 0 30px 30px 0;
    color: var(--mainBackgroundcolor);
    padding: 40px 0 0 60px;
}

.Home_PC-Products .Contents-List .ContextSide .HomeName{
    font-size: 32px;
    margin: 40px 0 0 0;
}

.Home_PC-Products .Contents-List .ContextSide .HomeNameSub{
    font-size: 24px;
    margin: 4px 0 0 0;
}

.Home_PC-Products .Contents-List .ContextSide .HomeDetail{
    font-size: 20px;
    height: 210px;
    margin: 8px 0 0 6px;
}

.Home_PC-Products .Contents-List .ContextSide .HomeType{ 
    font-size: 18px;
    margin: 40px 0 0 10px;
}

.Home_PC-Products .Contents-List .ContextSide .HomeType > div{ 
    border: 1px solid;
    border-color: var(--mainBackgroundcolor);
    padding: 20px;
}

.Home_PC-Products .Contents-List .ContextSide .HomeType > div:hover{ 
    background-color: var(--mainBackgroundcolor);
    color : var(--mainFontColor1);
}

.Home_PC-Products .Contents-List .ContextSide .HomeType .TypeDetail{ 
    font-size: 14px;
    margin-top: 10px;
}

.Home_PC-Products .Contents-List .ContextSide .HomeLink{
    margin: 0 0 0 20px;
}

.Home_PC-Products .Contents-List .ContextSide .HomeLink button{ 
    background-color: var(--mainBackgroundcolor);
    padding: 10px 20px;
    border-radius: 10px;
    color: var(--mainFontColor1);
    
    margin-top: 40px;
}

.Home_PC-Products .swiper-pagination-clickable{
    width: 200px;
    padding: 0;
    margin: 0;
}

.Home_PC-Products .swiper-pagination-bullet{
    width: 18px;
    height: 18px;
    border-radius: 4px;
    background-color: var(--mainFontColor2);
    transition: all ease 0.5s;
}

.Home_PC-Products .swiper-pagination-bullet-active{
    background-color: var(--mainWidgetcolor);
    transform: rotate(45deg);
    width: 20px;
    height: 20px;
}

.Home_PC-Products div[class^="NaviButton-"] {
    padding: 10px;
    font-size: 24px;
}

.Home_PC-Products div[class^="NaviButton-"]:hover {
    color: var(--mainFontColor2);
}

/* patent */
.Home_PC-Patent .Contents > div{
    margin-left: 26px;
    font-size: 24px;
}

.Home_PC-Patent .Contents-IMGs{
    position: relative;
}

.Home_PC-Patent .Contents-IMGs a{
    position: absolute;
    top: auto; bottom: auto; left: auto; right: auto;
    width: 840px; height: 380px;
    color: var(--mainFontColor2);
    font-size: 42px;
    transition: color 0.5s;
}

.Home_PC-Patent .Contents-IMGs:hover a{
    color: var(--mainBackgroundcolor);
}

.Home_PC-Patent .Contents-IMG img{
    width: 120px;
    object-fit: contain;
    opacity: 40%;
}
/* Trade */
.Home_PC-Trade .Contents{
    margin: 40px 40px 0 40px;
}

.Home_PC-Trade .companyList{
    max-width: 1120px;
}

.Home_PC-Trade .companyLists{
   
    border: 1px solid var(--mainBackgroundcolor);
}

.Home_PC-Trade .companyLists img{
    width: 220px;
    object-fit: contain;
}


/* news */
.Home_PC-News .Contents {
    margin: 0 40px;
}

.Home_PC-News .HeadLines {
    height: 100px;
    margin-left: 40px;
}

.Home_PC-News .HeadLines > * {
    pointer-events: none;
}

.Home_PC-News .HeadLines > i{
    padding : 30px 0;
    font-size: 20px;
}

.Home_PC-News .Contents .NContext{
    height: 0;
    padding: 0 40px;
    overflow: hidden;
    overflow-y: auto;
    
    border-bottom: 1px solid;
    border-bottom-color: var(--mainBackgroundcolor);
    transition: height 1s, padding 1s;
}

.Home_PC-News .Contents .NContext.active{
    min-height: 200px;
    flex: 1 1 0%;
    padding: 30px 40px;
}

.Home_PC-News .Contents .NContext p{
    margin: 5px 0;
}

.Home_PC-News .Contents a{
    font-size: 18px;
    font-weight: 500;
}

/* contact */
.Home_PC-Contact{
    padding: 140px 0 100px 0;
}
.Home_PC-Contact .BigName {
    font-size: 78px;
}

.Home_PC-Contact > div:nth-child(2){
    font-size: 20px;
    padding: 30px 0 40px 0;
}

.Home_PC-Contact > a{
    font-size: 22px;
    color: var(--mainFontColor2);
    transition: color 0.5s;
}

.Home_PC-Contact > a:hover{
    color : var(--mainBackgroundcolor);
}

/* mb */
.Home_MB-top {
    height: calc(100vh - 80px);
    padding-top: 50px;
}

.Home_MB-top p{
    text-align: center;
    font-size: 20px;
    padding: 1px 0;
}

.Home_MB-top .Text{
    text-align: center;
    font-size: 32px;
    font-weight: 900;
    margin-bottom: 10px;
}

.Home_MB-top img{
    width: 40%;
    margin-top: -60px;
    margin-bottom: 40px;
}

.Home_MB-Products, .Home_MB-Patent, .Home_MB-Trade, .Home_MB-News, .Home_MB-Contact{
    padding: 50px 30px;
    background-color: var(--mainFontColor1);
    color: var(--mainBackgroundcolor);
}

/* product */
.Home_MB-Products .Contents{
    margin-top: 30px;
    max-width: 100%;
}

.Home_MB-Products .Contents-List .MenuSide{
    background-color: var(--subBackgroundcolor);
    border-radius: 30px 30px 0 0;
}

.Home_MB-Products .Contents-List .MenuSide img{
    padding: 30px 0 0 0;
    height: 40vh;
    object-fit: contain;
    margin: 0 0px 0 0;
}

.Home_MB-Products .Contents-List .ContextSide{
    background-color: var(--mainFontColor1);
    color: var(--mainBackgroundcolor);
}

.Home_MB-Products .Contents-List .ContextSide .HomeName{
    font-size: 24px;
    margin: 40px 0 0 0;
}

.Home_MB-Products .Contents-List .ContextSide .HomeNameSub{
    font-size: 20px;
    margin: 4px 0 0 4px;
}

.Home_MB-Products .Contents-List .ContextSide .HomeDetail{
    font-size: 16px;
    margin: 8px 0 30px 8px;
}

.Home_MB-Products .Contents-List .ContextSide .HomeType{ 
    font-size: 16px;
    padding: 0 10px;
}

.Home_MB-Products .Contents-List .ContextSide .HomeType > div{ 
    border: 1px solid;
    border-color: var(--mainBackgroundcolor);
    padding: 14px;
}

.Home_MB-Products .Contents-List .ContextSide .HomeType > div:hover{ 
    background-color: var(--mainBackgroundcolor);
    color : var(--mainFontColor1);
}

.Home_MB-Products .Contents-List .ContextSide .HomeType .TypeDetail{ 
    font-size: 14px;
    margin-top: 10px;
}

.Home_MB-Products .Contents-List .ContextSide .HomeLink button{ 
    background-color: var(--mainBackgroundcolor);
    padding: 10px 40px;
    border-radius: 10px;
    color: var(--mainFontColor1);
}

.Home_MB-Products .swiper-pagination-clickable{
    width: 200px;
    padding: 0;
    margin: 0;
}

.Home_MB-Products .swiper-pagination-bullet{
    width: 18px;
    height: 18px;
    border-radius: 4px;
    background-color: var(--mainFontColor2);
    transition: all ease 0.5s;
}

.Home_MB-Products .swiper-pagination-bullet-active{
    background-color: var(--mainWidgetcolor);
    transform: rotate(45deg);
    width: 20px;
    height: 20px;
}

.Home_MB-Products div[class^="NaviButton-"] {
    padding: 10px;
    font-size: 24px;
}

.Home_MB-Products div[class^="NaviButton-"]:hover {
    color: var(--mainFontColor2);
}

/* patent */
.Home_MB-Patent .Contents > div:first-child{
    margin : 10px 0 0 20px;
    padding: 0 30px 0 0;
    font-size: 16px;
}

.Home_MB-Patent .Contents-IMGs{
    position: relative;
    margin-top: 30px;
    border: 1px solid var(--mainBackgroundcolor);
    border-radius: 20px;
    padding: 20px;
}

.Home_MB-Patent .Contents-IMGs a{
    position: absolute;
    top: auto; bottom: auto; left: auto; right: auto;
    /* width: 840px; height: 380px; */
    color: var(--mainBackgroundcolor);
    font-size: 30px;
}

.Home_MB-Patent .Contents-IMG img{
    width: 120px;
    object-fit: contain;
    opacity: 40%;
}
/* Trade */
.Home_MB-Trade .Contents{
    margin: 40px 0 0 0;
}

.Home_MB-Trade .companyList{
    max-width: 100%;
}

.Home_MB-Trade .CompanyLists{
    max-width: 100%;
}

.Home_MB-Trade .CompanyLists img{
    height: 100px;
    object-fit: contain;
}

/* news */
.Home_MB-News .HeadLines {
    height: 80px;
    padding: 0 20px;
}

.Home_MB-News .HeadLines > * {
    pointer-events: none;
}

.Home_MB-News .Contents .NContext{
    height: 0;
    padding: 0 20px;
    overflow: hidden;
    overflow-y: auto;
    
    border-bottom: 1px solid;
    border-bottom-color: var(--mainBackgroundcolor);
    transition: height 1s, padding 1s;
}

.Home_MB-News .Contents .NContext.active{
    min-height: 200px;
    flex: 1 1 0%;
}

.Home_MB-News .Contents .NContext p{
    margin: 5px 0;
}

.Home_MB-News .Contents a{
    font-size: 18px;
    font-weight: 500;
}

/* contact */
.Home_MB-Contact{
    padding: 100px 0;
}
.Home_MB-Contact .BigName {
    font-size: 42px;
}

.Home_MB-Contact > div:nth-child(2){
    font-size: 13px;
    padding: 20px 0 20px 0;
}

.Home_MB-Contact > a{
    font-size: 22px;
    color: var(--mainBackgroundcolor);
}

@media only screen and (max-width: 380px) {
    .Home_MB{
        zoom: 0.6;
    }
}

@media only screen and (max-width: 900px) {
    .Home_PC{
        display: none ;
    }
    .Home_MB {
        display: flex;
    }
}

@media only screen and (min-width: 901px) and (max-width : 1199px) {
    .Home_PC{
        display: flex ;
        zoom: 0.7;
    }
    .Home_MB {
        display: none;
    }
}
  
@media only screen and (min-width: 1200px) {
    .Home_PC{
        display: flex ;
    }
    .Home_MB {
        display: none;
    }
}

@media only screen and (min-width: 1921px) and (max-width: 2560px ) {
    .Home_PC{
        zoom: 1.2;
    }
}

@media only screen and (min-width: 2561px){
    .Home_PC{
        zoom: 1.4;
    }
}