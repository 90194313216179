.Facility{
    font-family : 'ONE-Mobile-Title';
}

/* pc */
.Facility_PC-Facility{
    font-size: 32px;
    font-weight: normal;
}

/* history */
.Facility_PC-Facility .MenuArea{
    position: sticky;
    top : 0;
    height: 40vh;
    min-width: 200px;
    padding : 50px 0 0 10px;
    overflow-y : auto;
}
.Facility_PC-Facility .Facilitys{
    width: 80%;
    padding: 50px 0 0 60px;
}   

.Facility_PC-Facility .FacilityList .ListIMG img{
    object-fit: cover;
    width: 440px;
    border-radius: 4px;
}


.Facility_PC-Facility .FacilityList .List-Top{
    height: 40px;
    font-size: 14px;
    padding: 0 10px 10px 10px;
    border-bottom: 1px solid;
    border-bottom-color: var(--mainFontColor1);
}

.Facility_PC-Facility .FacilityList .List{
    max-width: 100%;
    overflow: hidden;
    border-bottom: 1px solid;
    border-bottom-color: var(--mainFontColor1);
    border-top: 1px solid;
    border-top-color: var(--mainFontColor1);
}

.Facility_PC-Facility .FacilityList .Lists > div{
    height: 40px;
    font-size: 12px;
    font-weight: lighter;
    padding: 0 10px;
    border-bottom: 1px solid;
    border-bottom-color: var(--mainFontColor1);
}

.Facility_PC-Facility .FacilityList .PagingTool{
    margin-top: 20px;
}

.Facility_PC-Facility .FacilityList .PagingTool .swiper-button-disabled::before{
    color: var(--mainFontColor2);
}

.Facility_PC-Facility .FacilityList .PagingTool .swiper-pagination-bullet{
    font-size: 14px;
    width: 28px;
    height: 28px;
    border-radius: 4px;
    padding: 4px;
    text-align: center;
}

.Facility_PC-Facility .FacilityList .PagingTool .swiper-pagination-bullet-active{
    background-color: var(--mainFontColor1);
    color: var(--subBackgroundcolor);
}
/* mobile */
.Facility_MB{
    font-size: 28px;
    font-weight: normal;
}

.Facility_MB > .FacilityBanner {
    padding: 40px 30px 0 30px;
    font-size: 36px;
    font-weight: bold;
}

.Facility_MB > .FacilityList {
    padding: 0 30px;
}

.Facility_MB .FacilityList .ListIMG img{
    object-fit: cover;
    height: 120px;
    border-radius: 4px;
    margin-bottom: 10px;
}

.Facility_MB .FacilityList .List{
    max-width: 100%;
    border-bottom: 1px solid;
    border-bottom-color: var(--mainFontColor1);
}

.Facility_MB .FacilityList .Lists{
    width: 1200px;
    font-size: 12px;
}

.Facility_MB .FacilityList .List-Top {
    border-top: 1px solid;
    border-bottom: 1px solid;
    border-color: var(--mainFontColor1);
    height: 50px;
}

.Facility_MB .FacilityList .List-Bottom {
    border-bottom: 1px solid;
    border-color: var(--mainFontColor1);
    overflow: hidden;
    height: 30px;
}

.Facility_MB .FacilityList .List-Bottom > div {
    padding: 2px;
    white-space: nowrap;
    text-overflow : ellipsis;
    overflow: hidden;
    min-width: 20px;
}

.Facility_MB .FacilityList .PagingTool{
    margin-top: 20px;
}

.Facility_MB .FacilityList .PagingTool .swiper-button-disabled::before{
    color: var(--mainFontColor2);
}

@media only screen and (max-width: 380px) {
    .Facility_MB{
        zoom: 0.6;
    }
}

@media only screen and (max-width: 900px) {
    .Facility_PC{
        display: none ;
    }
    .Facility_MB {
        display: flex;
    }
}

@media only screen and (min-width: 901px) and (max-width : 1199px) {
    .Facility_PC{
        display: flex ;
        zoom: 0.7;
    }
    .Facility_MB {
        display: none;
    }
}
  
@media only screen and (min-width: 1200px) {
    .Facility_PC{
        display: flex ;
    }
    .Facility_MB {
        display: none;
    }
}

@media only screen and (min-width: 1921px) and (max-width: 2560px ) {
    .Facility_PC{
        zoom: 1.2;
    }
}

@media only screen and (min-width: 2561px){
    .Facility_PC{
        zoom: 1.4;
    }
}