.Product{
    font-family : 'ONE-Mobile-Title';
}

.Product img{
    z-index: 998;
}

/* pc */
.Product_PC-products{
    font-size: 32px;
    font-weight: normal;
}

.Product_PC-IMG{
    position: fixed;
    top: 0; left: 0; right: 0;
    height: 100vh;
    background-color: black;
    z-index: 999;
    font-size: 30px;
    padding: 30px;
    display: none;
}

.Product_PC-IMG img{
    height: 80vh;
    width: 80%;
    object-fit: contain;
}

.Product_PC-IMG.active{
    display: flex;
}

/* history */
.Product_PC-products .MenuArea{
    position: sticky;
    top : 0;
    height: calc(100vh - 80px);
    min-width: 200px;
    padding : 50px 0 0 10px;
    overflow-y : auto;
}

.Product_PC-products .MenuAreaList > ul > li{
    font-size: 24px;
    margin-left : 4px;
    margin-bottom: 16px;
}

.Product_PC-products .MenuAreaList > ul ul{
    margin : 10px 0 30px 4px;
}

.Product_PC-products .MenuAreaList > ul ul > li{
    font-size: 18px;
    margin-bottom: 10px;
}

.Product_PC-products .MenuAreaList > ul ul > li> a{
    position: relative;
}

.Product_PC-products .MenuAreaList > ul ul > li > a::after{
    content: "";
    position: absolute;
    top: 120%;
    left: 0;
    width: 0;
    height: 2px;
    background-color: var(--mainFontColor1);
    transition: width 0.6s;
}

.Product_PC-products .MenuAreaList > ul ul > li:hover > a::after{
    content: "";
    width: 100%;
}

.Product_PC-products .ProductList{
    max-width: 80%;
    padding: 0 0 0 60px;
}

.Product_PC-products .Middles{
    font-size: 28px;
}

.Product_PC-products .Lists{
    width: 400px;
    font-size: 12px;
    padding: 20px 10px 10px 10px;
    border: 1px solid;
    border-color: var(--mainFontColor1);
    border-radius: 10px;
}

.Product_PC-products .Lists.PersonalPG{
    max-width: 290px;
}

.Product_PC-products .Lists img {
    width: 90%;
    height: 150px;
    object-fit: contain;
    margin : auto auto 10px auto;
}

.Product_PC-products div[class^="swiper-button-"]{
    display: none;
}

.Product_PC-products .swiper-navi{
    display: flex;
    position: relative;
    width: 50px;
    height: 220px;
}

.Product_PC-products .swiper-navi div[class^="swiper-button-"]{
    display: flex;
    position: absolute;
    left: auto;
    right : 0px;
    background-color: var(--mainBackgroundcolor);
    border: 2px solid;
    border-color: var(--mainFontColor1);
    width: 36px;
    height: 36px;
    border-radius: 100%;
}

.Product_PC-products .swiper-navi div[class^="swiper-button-"]::after{
    color: var(--mainFontColor1);
    font-size: 20px;
    font-weight: bold;
}

.Product_PC-products .swiper-navi .swiper-button-prev {
    top: 80px;
    bottom: auto;
}

.Product_PC-products .swiper-navi .swiper-button-next {
    top: auto;
    bottom: 80px;
}

.Product_PC-products .Sink_Context img {
    height: 360px;
    margin-left: 20px;
}

.Product_PC-products .Sink_Context span {
    font-size: 24px;
}

.Product_PC-products .Sink_Context p {
    font-size: 20px;
}

.Product_PC-products .Sink_Context button {
    font-size: 16px;
    color: var(--mainBackgroundcolor);
    background-color: var(--mainFontColor1);
    padding: 10px 20px;
    border-radius: 20px;
}
/* mobile */
.Product_MB{
    font-size: 28px;
    font-weight: normal;
}

.Product_MB-IMG{
    position: fixed;
    top: 0; left: 0; right: 0;
    height: 100vh;
    background-color: black;
    z-index: 999;
    font-size: 30px;
    padding: 30px;
    display: none;
}

.Product_MB-IMG img{
    width: 90%;
    height: 80vh;
    object-fit: contain;
}

.Product_MB-IMG.active{
    display: flex;
}

.Product_MB > .ProductBanner {
    /* height: 160px; */
    padding: 40px 30px 0 30px;
    background-color: var(--subBackgroundcolor);
    font-size: 36px;
    font-weight: bold;
    border-bottom: 2px solid;
    border-bottom-color: var(--mainFontColor1);
}

.Product_MB > .ProductBanner > .MenuBar {
    font-size: 28px;
    padding: 10px 20px;
    background-color: var(--mainBackgroundcolor);
    border-radius: 20px;
}

.Product_MB > .ProductBanner > .MenuBar i{
    color: var(--mainFontColor2);
}

.Product_MB > .ProductBanner .MenuArea{
    position: fixed;
    top: 0; left: 0; right: 0;
    height: 100vh;
    background-color: rgba(0,0,0,0.5);
    z-index: 999;
    font-size: 24px;
    font-weight: normal;
    padding: 30px;
    display: none;
}

.Product_MB > .ProductBanner .MenuArea.active{
    display: flex;
}

.Product_MB > .ProductBanner .MenuAreaList{
    width: 90%;
    border-radius: 20px;
    background-color: var(--mainBackgroundcolor);
    padding: 30px;
    margin: 30px;
    margin: auto;
    overflow-y: auto;
}

.Product_MB > .ProductBanner .MenuAreaList a{
    width: 100%;
    padding: 10px;
}

.Product_MB > .ProductList {
    padding: 0 30px;
}

.Product_MB > .ProductList .Middles{
    font-size: 24px;
}

.Product_MB > .ProductList .Lists{
    height: 200px;
    font-size: 12px;
    padding: 20px 10px 10px 10px;
    border: 1px solid;
    border-color: var(--mainFontColor1);
    border-radius: 10px;
}

.Product_MB > .ProductList .Lists img {
    width: 95%;
    height: 120px;
    object-fit: contain;
    margin : auto auto 10px auto;
}

.Product_MB > .ProductList .swiper-wrapper{
    position: relative;
}

.Product_MB > .ProductList .swiper-pagination-progressbar {
    position: absolute;
    top: auto; bottom: 0;
}

.Product_MB > .ProductList .swiper-pagination-progressbar-fill{
    background-color: var(--mainFontColor2);
}

.Product_MB > .ProductList .Sink_Context img {
    height: 300px;
}

.Product_MB > .ProductList .Sink_Context span {
    font-size: 24px;
}

.Product_MB > .ProductList .Sink_Context p {
    font-size: 18px;
}

.Product_MB > .ProductList .Sink_Context button {
    font-size: 16px;
    color: var(--mainBackgroundcolor);
    background-color: var(--mainFontColor1);
    padding: 10px 20px;
    border-radius: 20px;
}

@media only screen and (max-width: 380px) {
    .Product_MB{
        zoom: 0.6;
    }
}

@media only screen and (max-width: 900px) {
    .Product_PC{
        display: none ;
    }
    .Product_MB {
        display: flex;
    }
}

@media only screen and (min-width: 901px) and (max-width : 1199px) {
    .Product_PC{
        display: flex ;
        zoom: 0.7;
    }
    .Product_MB {
        display: none;
    }
}
  
@media only screen and (min-width: 1200px) {
    .Product_PC{
        display: flex ;
    }
    .Product_MB {
        display: none;
    }
}

@media only screen and (min-width: 1921px) and (max-width: 2560px ) {
    .Product_PC{
        zoom: 1.2;
    }
}

@media only screen and (min-width: 2561px){
    .Product_PC{
        zoom: 1.4;
    }
}