.ESG{
    font-family : 'ONE-Mobile-Title';
}

.ESG img{
    z-index: 998;
}

/* pc */
.ESG_PC-banner{
    padding: 0 60px;
    height: 400px;
    box-sizing: border-box;
    border-bottom-width: 2px;
    border-bottom-color: var(--mainFontColor1);
    color : var(--mainBackgroundcolor);
    background-image: url('https://firebasestorage.googleapis.com/v0/b/shinafastec.appspot.com/o/ESG%2Fpngtree-greenery-scenery-breathtaking-high-definition-photographic-creative-image-picture-image_15428296.jpg?alt=media&token=12bf1030-6607-41b6-bfe3-34b54411fcf5');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 100% 30%;
    background-color: rgba(0,0,0,0.3);
    background-blend-mode: multiply;
}

.ESG_PC-banner-top{
    font-size: 56px;
    font-weight: bold;
}

.ESG_PC-banner-top:last-child{
    font-weight: normal;
}


.ESG_PC-ESGs{
    font-size: 32px;
    font-weight: normal;
    border-bottom: 1px solid var(--mainFontColor1);
}

.ESG_PC-ESGs .MenuAreaList > ul > li{
    font-size: 24px;
    border-right: 1px solid var(--mainFontColor1);
    border-left: 1px solid var(--mainFontColor1);
    height: 80px;
}

.ESG_PC-ESGs .MenuAreaList > ul > li:first-child{
    border-left-width: 0px;
}

.ESG_PC-ESGs .MenuAreaList > ul > li:last-child{
    border-right-width: 0px;
}

/* MB */
.ESG_MB-banner{
    padding: 0 20px;
    height: 300px;
    box-sizing: border-box;
    border-bottom-width: 2px;
    border-bottom-color: var(--mainFontColor1);
    color : var(--mainBackgroundcolor);
    background-image: url('https://firebasestorage.googleapis.com/v0/b/shinafastec.appspot.com/o/ESG%2Fpngtree-greenery-scenery-breathtaking-high-definition-photographic-creative-image-picture-image_15428296.jpg?alt=media&token=12bf1030-6607-41b6-bfe3-34b54411fcf5');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 100% 40%;
    background-color: rgba(0,0,0,0.3);
    background-blend-mode: multiply;
}

.ESG_MB-banner-top{
    font-size: 56px;
    font-weight: bold;
}

.ESG_MB-banner-top:last-child{
    font-weight: normal;
}

.ESG_MB-ESGs{
    font-size: 20px;
    font-weight: normal;
    border-bottom: 1px solid var(--mainFontColor1);
}

.ESG_MB-ESGs .MenuAreaList > ul > li{
    font-size: 30px;
    border-right: 1px solid var(--mainFontColor1);
    border-left: 1px solid var(--mainFontColor1);
    height: 60px;
}

.ESG_MB-ESGs .MenuAreaList > ul > li:first-child{
    border-left-width: 0px;
}

.ESG_MB-ESGs .MenuAreaList > ul > li:last-child{
    border-right-width: 0px;
}


@media only screen and (max-width: 380px) {
    .ESG_MB{
        /* zoom: 0.6; */
    }
}

@media only screen and (max-width: 900px) {
    .ESG_PC{
        display: none ;
    }
    .ESG_MB {
        display: flex;
    }
}

@media only screen and (min-width: 901px) and (max-width : 1199px) {
    .ESG_PC{
        display: flex ;
        zoom: 0.7;
    }
    .ESG_MB {
        display: none;
    }
}
  
@media only screen and (min-width: 1200px) {
    .ESG_PC{
        display: flex ;
    }
    .ESG_MB {
        display: none;
    }
}

@media only screen and (min-width: 1921px) and (max-width: 2560px ) {
    .ESG_PC{
        zoom: 1.2;
    }
}

@media only screen and (min-width: 2561px){
    .ESG_PC{
        zoom: 1.4;
    }
}