.ESGDetail_PC .Title{
    font-size: 42px;
    font-weight: bold;
}

.ESGDetail_PC .ESGBar{
    background-color: black;
}

.ESGDetail_PC .ESGComment{
    padding: 10px;
}

.ESGDetail_PC .ESGComment p{
    padding: 20px 10px;
    font-size: 20px;
}

.ESGDetail_PC .ESGIMG{
    width: 400px;
    height: 300px;
    object-fit: cover; /* 이미지를 요소 크기에 맞게 조정 */
    overflow: hidden; /* 넘치는 부분 숨기기 */
}

.ESGDetail_PC .ESGReport{
    font-size: 36px;
}

.ESGDetail_PC .ESG_PC-CI-lists{
    font-size: 18px;
    padding: 10px 20px;
    border-bottom: 1px solid black;
}

.ESGDetail_PC .ESG_PC-CI-lists:hover{
    font-weight: bold;
    cursor: pointer;
}

/* mb */
.ESGDetail_MB{
    padding: 10px;
}

.ESGDetail_MB .Title{
    font-size: 36px;
    font-weight: bold;
    padding : 0 0 0 8px;
}

.ESGDetail_MB .ESGBar{
    background-color: black;
}

.ESGDetail_MB .ESGComment{
    padding:  10px 0;
}

.ESGDetail_MB .ESGComment p{
    padding: 0px 10px 20px 10px;
    font-size: 20px;
}

.ESGDetail_MB .ESGIMG{
    width: 100%;
    object-fit: cover; /* 이미지를 요소 크기에 맞게 조정 */
    overflow: hidden; /* 넘치는 부분 숨기기 */   
}

.ESGDetail_MB .ESGReport{
    font-size: 30px;
}

.ESGDetail_MB .ESG_PC-CI-lists{
    font-size: 16px;
    padding: 10px 20px;
    border-bottom: 1px solid black;
}

.ESGDetail_MB .ESG_PC-CI-lists:hover{
    font-weight: bold;
    cursor: pointer;
}



@media only screen and (max-width: 900px) {
    .ESGDetail_PC{
        display: none ;
    }
    .ESGDetail_MB {
        display: flex;
    }
}
  
@media only screen and (min-width: 901px) {
    .ESGDetail_PC{
        display: flex ;
    }
    .ESGDetail_MB {
        display: none;
    }
}
