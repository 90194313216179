.Patent{
    font-family : 'ONE-Mobile-Title';
}

.Patent-IMG{
    position: fixed;
    top: 0; left: 0; right: 0;
    height: 100vh;
    background-color: black;
    z-index: 999;
    font-size: 30px;
    padding: 30px;
    display: none;
}

.Patent-IMG img{
    height: 80vh;
    width: 80%;
    object-fit: contain;
}

.Patent-IMG.active{
    display: flex;
}

/* pc */
.Patent_PC-Patent{
    font-size: 32px;
    font-weight: normal;
}

.Patent_PC-Patent .MenuArea{
    position: sticky;
    top : 0px;
    height: calc(100vh - 80px);
    min-width: 200px;
    padding : 50px 0 0 10px;
    overflow-y : auto;
}

.Patent_PC-Patent .Patents{
    width: 80%;
    padding: 50px 0 0 60px;
}   

.Patent_PC-Patent .PatentList{
    font-size: 12px;
}

.Patent_PC-Patent .PatentList .List div:last-child{
    font-size: 10px;
    color: var(--mainFontColor2);
}

.Patent_PC-Patent .PatentList .List img{
    object-fit: contain;
    width: 250px;
    padding: 0px;
    border-radius: 4px;
    border: 1px solid;
    border-color: var(--mainFontColor2);
}

/* mobile */
.Patent_MB{
    font-size: 28px;
    font-weight: normal;
}

.Patent_MB > .PatentBanner {
    padding: 40px 30px 0 30px;
    font-size: 36px;
    font-weight: bold;
}

.Patent_MB .PatentList{
    font-size: 12px;
    padding: 0 30px;
}

.Patent_MB .PatentList .List div:last-child{
    font-size: 10px;
    color: var(--mainFontColor2);
}

.Patent_MB .PatentList .List img{
    object-fit: contain;
    width: 250px;
    padding: 0px;
    border-radius: 4px;
    border: 1px solid;
    border-color: var(--mainFontColor2);
}

@media only screen and (max-width: 380px) {
    .Patent_MB{
        zoom: 0.6;
    }
}

@media only screen and (max-width: 900px) {
    .Patent_PC{
        display: none ;
    }
    .Patent_MB {
        display: flex;
    }
}

@media only screen and (min-width: 901px) and (max-width : 1199px) {
    .Patent_PC{
        display: flex ;
        zoom: 0.7;
    }
    .Patent_MB {
        display: none;
    }
}
  
@media only screen and (min-width: 1200px) {
    .Patent_PC{
        display: flex ;
    }
    .Patent_MB {
        display: none;
    }
}

@media only screen and (min-width: 1921px) and (max-width: 2560px ) {
    .Patent_PC{
        zoom: 1.2;
    }
}

@media only screen and (min-width: 2561px){
    .Patent_PC{
        zoom: 1.4;
    }
}