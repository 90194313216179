:root{
  --mainBackgroundcolor : #ffffff;
  --subBackgroundcolor : #f5f5f5;
  --mainWidgetcolor : #CE171E;
  --mainFontColor1 : #373737;
  --mainFontColor2 : #949494;
}

html, body{
  margin: 0;
  padding : 0;
  background-color: var(--mainBackgroundcolor);
  color : var(--mainFontColor1);
  position: relative;
  box-sizing: border-box;
}

ul, li {
  margin: 0; padding: 0; list-style: none;
}

a{
  color: inherit; text-decoration: none;
}

.con {
  max-width: 1200px;
}

.App{
  min-height: 100vh;
  white-space: pre-wrap;
}

.Top-Menu-Fix {
  width: 100%;
  height: auto;
  position: sticky;
  top : 0;
  z-index: 999;
}