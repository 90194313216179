.Menu{
    font-family: 'ONE-Mobile-Title';
    background-color: var(--mainBackgroundcolor);
}

/* PC */
.Menu_PC {
    padding: 0 30px;
    font-size: 18px;
    height: 80px;
    position: relative;
}

.Menu_PC-1line{
    width: 1200px;
}

.Menu_PC-left img {
    height: 50px;
}

.Menu_PC-right{
    width: 600px;
}

.Menu_PC-bottom{
    height: 150px;
    padding : 20px 0;
    font-size: 16px;
    position: absolute;
    top : -70px;
    left: 0;
    right: 0;
    background-color: var(--mainBackgroundcolor);
    opacity: 90%;
    z-index: -1;
    transition: top 0.6s;
}

.Menu_PC-right:hover > .Menu_PC-bottom{
    top: 80px;
}

.Menu_PC-bottom-group{
    width: 1200px;
}

.Menu_PC-bottom-GMenu{
    width: 120px;
    opacity: 100%;
}

/* 모바일 */
.Menu_MB {
    height: 80px;
    padding: 0 20px;
    font-size: 24px;
}

.Menu_MB-left img {
    height: 50px;
}

.Menu_MB-right i{
    color : var(--mainFontcolor);
    font-size: 36px;
}

.Menu_MB-lists {
    position : absolute;
    top: 0;
    left: -80%;
    width: 80%;
    height: 100vh;
    background: var(--mainBackgroundcolor);
    transition: left 0.5s;
    border-right: 2px solid;
    border-right-color: var(--mainFontcolor1);
    overflow: hidden auto;
}

.Menu_MB-lists.Output {
    left:0%;
}

.Menu_MB-lists-menu{
    padding: 20px 0 20px 20px;
}

.Menu_MB-lists-menu > li{
    padding: 30px 0 10px 10px;    
    font-size: 26px;
}

.Menu_MB-lists-menu > li > ul > li{
    padding: 10px 0 0 4px;    
    font-size: 18px;
}

@media only screen and (max-width: 380px) {
    .Menu_MB-left{
        zoom: 0.8;
    }

    .Menu_MB-right i{
        font-size: 32px;
    }

    .Menu_MB{
        padding: 10px 20px;
    } 
}

@media only screen and (max-width: 900px) {
    .Menu_PC{
        display: none ;
    }
    .Menu_MB {
        display: flex;
    }
}

@media only screen and (min-width: 901px) {
    .Menu_PC{
        display: flex ;
    }

    .Menu_MB {
        display: none;
    }
}

@media only screen and (min-width: 901px) and (max-width: 1199px) {
    .Menu_PC-1line, .Menu_PC-bottom-group{
        width: 900px;
    }
}

@media only screen and (min-width: 1921px) and (max-width: 2560px ) {
    .Menu_PC-1line, .Menu_PC-bottom-group{
        width: 1440px;
    }
}

@media only screen and (min-width: 2561px){
    .Menu_PC-1line, .Menu_PC-bottom-group{
        width: 1680px;
    }
}